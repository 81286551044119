var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"circle","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"Customer Details","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Customer Details ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Enter Customer Details. ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Firstname","label-for":"firstname"}},[_c('validation-provider',{attrs:{"name":"firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstname","state":errors.length > 0 ? false:null,"placeholder":"Firstname"},model:{value:(_vm.form.customer.firstname),callback:function ($$v) {_vm.$set(_vm.form.customer, "firstname", $$v)},expression:"form.customer.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Lastname","label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","state":errors.length > 0 ? false:null,"placeholder":"Lastname"},model:{value:(_vm.form.customer.lastname),callback:function ($$v) {_vm.$set(_vm.form.customer, "lastname", $$v)},expression:"form.customer.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Address Line 1","label-for":"addressline1"}},[_c('validation-provider',{attrs:{"name":"address line 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"addressline1","state":errors.length > 0 ? false:null,"placeholder":"Address Line 1"},model:{value:(_vm.form.customer.address1),callback:function ($$v) {_vm.$set(_vm.form.customer, "address1", $$v)},expression:"form.customer.address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Address Line 2","label-for":"addressline2"}},[_c('validation-provider',{attrs:{"name":"address line 2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"addressline2","state":errors.length > 0 ? false:null,"placeholder":"Address Line 2"},model:{value:(_vm.form.customer.address2),callback:function ($$v) {_vm.$set(_vm.form.customer, "address2", $$v)},expression:"form.customer.address2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false:null,"placeholder":"john.doe@email.com"},model:{value:(_vm.form.customer.email),callback:function ($$v) {_vm.$set(_vm.form.customer, "email", $$v)},expression:"form.customer.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Phone number (ideally mobile)","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"paphonessword","state":errors.length > 0 ? false:null},model:{value:(_vm.form.customer.phone),callback:function ($$v) {_vm.$set(_vm.form.customer, "phone", $$v)},expression:"form.customer.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Postcode","label-for":"postcode"}},[_c('validation-provider',{attrs:{"name":"postcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"postcode","state":errors.length > 0 ? false:null,"placeholder":"PO123dy"},model:{value:(_vm.form.customer.postcode),callback:function ($$v) {_vm.$set(_vm.form.customer, "postcode", $$v)},expression:"form.customer.postcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Device","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Device Info ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"first-name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first-name","placeholder":"John","state":errors.length > 0 ? false:null},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last-name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last-name","state":errors.length > 0 ? false:null,"placeholder":"Doe"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Country","label-for":"country","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"country","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countryName,"selectable":function (option) { return ! option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.selectedContry),callback:function ($$v) {_vm.selectedContry=$$v},expression:"selectedContry"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Language","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Language","label-for":"language","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"language","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.languageName,"selectable":function (option) { return ! option.value.includes('nothing_selected'); },"label":"text"},model:{value:(_vm.selectedLanguage),callback:function ($$v) {_vm.selectedLanguage=$$v},expression:"selectedLanguage"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Address","before-change":_vm.validationFormAddress}},[_c('validation-observer',{ref:"addressRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Address ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Enter Your Address.")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Address","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 0 ? false:null,"placeholder":"98 Borough bridge Road, Birmingham"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Landmark","label-for":"landmark"}},[_c('validation-provider',{attrs:{"name":"Landmark","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"landmark","state":errors.length > 0 ? false:null,"placeholder":"Borough bridge"},model:{value:(_vm.landMark),callback:function ($$v) {_vm.landMark=$$v},expression:"landMark"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Pincode","label-for":"pincode"}},[_c('validation-provider',{attrs:{"name":"Pincode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"pincode","state":errors.length > 0 ? false:null,"type":"number","placeholder":"658921"},model:{value:(_vm.pincode),callback:function ($$v) {_vm.pincode=$$v},expression:"pincode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"City","label-for":"city"}},[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","state":errors.length > 0 ? false:null,"placeholder":"Birmingham"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Social Links","before-change":_vm.validationFormSocial}},[_c('validation-observer',{ref:"socialRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Social Links ")]),_c('small',{staticClass:"text-muted"},[_vm._v("Enter Your Social Links")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Twitter","label-for":"twitter"}},[_c('validation-provider',{attrs:{"name":"Twitter","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"twitter","state":errors.length > 0 ? false:null,"placeholder":"https://twitter.com/abc"},model:{value:(_vm.twitterUrl),callback:function ($$v) {_vm.twitterUrl=$$v},expression:"twitterUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Facebook","label-for":"facebook"}},[_c('validation-provider',{attrs:{"name":"Facebook","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"facebook","state":errors.length > 0 ? false:null,"placeholder":"https://facebook.com/abc"},model:{value:(_vm.facebookUrl),callback:function ($$v) {_vm.facebookUrl=$$v},expression:"facebookUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Google+","label-for":"google-plus"}},[_c('validation-provider',{attrs:{"name":"Google+","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"google-plus","state":errors.length > 0 ? false:null,"placeholder":"https://plus.google.com/abc"},model:{value:(_vm.googleUrl),callback:function ($$v) {_vm.googleUrl=$$v},expression:"googleUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"LinkedIn","label-for":"linked-in"}},[_c('validation-provider',{attrs:{"name":"LinkedIn","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"linked-in","state":errors.length > 0 ? false:null,"placeholder":"https://linkedin.com/abc"},model:{value:(_vm.linkedinUrl),callback:function ($$v) {_vm.linkedinUrl=$$v},expression:"linkedinUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }